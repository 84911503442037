

















































































































































































































































































































































































































































import { address, email, legalName, phone, website } from '@/app.config.json'
import SiteSection from '@/components/SiteSection.vue'
import { formatPhoneNumber } from '@/utils/phone'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PrivacyNotice',

  components: {
    SiteSection,
  },

  setup() {
    return {
      legalName,
      address,
      phone,
      formattedPhone: formatPhoneNumber(phone),
      email,
      website,
    }
  },
})
